<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import SettingService from '@/services/settings'
import { VMoney } from 'v-money'

export default {
  page: {
    title: "Regras de Pagamento",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  directives: { money: VMoney },
  data() {
    return {
      title: "Regras de Pagamento",
      settings: {},
      paymentConditions: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      }
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let loader = this.$loading.show();

      try {
        const paymentConditionsResult = await SettingService.getPaymentConditions();
        this.paymentConditions = paymentConditionsResult.data;

        const settingsResult = await SettingService.getSettings();
        this.settings = settingsResult.data;

        this.formatProduct();

        loader.hide();
      } catch (err) {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao carregar as configurações');
      }
    },
    async save() {
      let loader = this.$loading.show();

      try {
        const data = this.settings.paymentConditions.map(item => {
          return {
            ...item,
            minimumSaleValue: Number(item.minimumSaleValue.toString().replace('.', '').replace(',', '.')),
          }
        });

        await SettingService.update({
          paymentConditions: data,
          limitToNotifyPaymentConditions: Number(this.settings.limitToNotifyPaymentConditions.toString().replace('.', '').replace(',', '.')),
          cashPaymentDiscount: Number(this.settings.cashPaymentDiscount.toString().replace('.', '').replace(',', '.')),
          cashPaymentDiscountMobwit: Number(this.settings.cashPaymentDiscountMobwit.toString().replace('.', '').replace(',', '.')),
        });

        const settingsResult = await SettingService.getSettings();
        this.settings = settingsResult.data;
        this.formatProduct();

        this.$toast.open('Configuração atualizada com sucesso');

        loader.hide();
      } catch (err) {
        loader.hide();

        // eslint-disable-next-line no-console
        console.log(err.response);

        if (err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro ao salvar as configurações');
      }
    },
    formatProduct() {
      this.settings.paymentConditions.sort((a, b) => {
        if (a.minimumSaleValue < b.minimumSaleValue) return -1;
        if (a.minimumSaleValue > b.minimumSaleValue) return 1;
        return 0;
      })

      this.settings.paymentConditions.forEach(item => {
        item.minimumSaleValue = item.minimumSaleValue.toFixed(2).replace('.', ',');
      });

      this.settings.limitToNotifyPaymentConditions = (this.settings.limitToNotifyPaymentConditions || 0).toFixed(2).replace('.', ',');
      this.settings.cashPaymentDiscount = (this.settings.cashPaymentDiscount || 0).toFixed(2).replace('.', ',');
      this.settings.cashPaymentDiscountMobwit = (this.settings.cashPaymentDiscountMobwit || 0).toFixed(2).replace('.', ',');
    },
    addRule() {
      this.settings.paymentConditions.push({});
    },
    removeRule(index) {
      this.settings.paymentConditions.splice(index, 1);
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-sm-3 col-lg-2">
              <div class="form-group">
                <label for="limitToNotifyPaymentConditions">% Notificação</label>

                <input
                  id="limitToNotifyPaymentConditions"
                  name="limitToNotifyPaymentConditions"
                  type="tel"
                  class="form-control"
                  v-money="money"
                  v-model="settings.limitToNotifyPaymentConditions"
                />
              </div>
            </div>

            <div class="col-sm-3 col-lg-2">
              <div class="form-group">
                <label for="cashPaymentDiscount">% Desconto à Vista</label>

                <input
                  id="cashPaymentDiscount"
                  name="cashPaymentDiscount"
                  type="tel"
                  class="form-control"
                  v-money="money"
                  v-model="settings.cashPaymentDiscount"
                />
              </div>
            </div>

            <div class="col-sm-3 col-lg-2">
              <div class="form-group">
                <label for="cashPaymentDiscountMobwit">% Desconto (MOBWIT)</label>

                <input
                  id="cashPaymentDiscountMobwit"
                  name="cashPaymentDiscountMobwit"
                  type="tel"
                  class="form-control"
                  v-money="money"
                  v-model="settings.cashPaymentDiscountMobwit"
                />
              </div>
            </div>
          </div>

          <div class="row" v-for="(paymentSettings, index) in settings.paymentConditions" :key="index">
            <div class="col-lg-4 col-md-5 col-6">
              <div class="form-group">
                <label :for="'paymentCondition' + index">Condição de Pagamento</label>
                <!-- <input :id="'paymentCondition' + index" type="text" class="form-control" v-model="variation.variation_description" /> -->

                <div>
                  <select class="form-control custom-select" v-model="paymentSettings.paymentCondition">
                    <option v-for="(item, i) in paymentConditions" :key="i" :value="item._id">{{item.description}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-lg-2 col-md-4 col-5">
              <div class="form-group">
                <label for="productStock">Valor Mínimo Pedido</label>

                <input
                  id="minimumSaleValue"
                  name="minimumSaleValue"
                  type="tel"
                  class="form-control"
                  v-money="money"
                  v-model="paymentSettings.minimumSaleValue"
                />
              </div>
            </div>

            <div class="col-1 mt-3 align-self-center">
              <a href="javascript:void(0);" class="action-icon text-danger" @click="removeRule(index)">
                <i class="mdi mdi-trash-can font-size-14"></i>
              </a>
            </div>

            <!-- <div class="col-sm-1 mt-3 align-self-center">
              <a class="action-icon text-danger" @click="removeVariation(index)">
                <i class="mdi mdi-trash-can font-size-18"></i>
              </a>
            </div> -->
          </div>

          <div class="row mt-2">
            <div class="col-sm-5">
              <div class="text-right">
                <b-button pill variant="outline-primary" @click="addRule">+ Nova Regra</b-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-4 mb-4 align-items-center">
      <div class="col-sm-6">
        <button type="button" class="btn btn-primary btn-rounded" @click="save">
          <i class="mdi mdi-check mr-1"></i> Salvar Regras de Pagamento
        </button>
      </div>
    </div>
  </Layout>
</template>
